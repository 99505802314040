import { observer } from "mobx-react-lite"
import React, { useEffect } from "react"
import {
    EuiPage,
    EuiPageBody,
    EuiPageContent,
    EuiPageContentBody,
    EuiText,
    EuiSpacer
} from "@elastic/eui";
import HailCXCenterImageHeader from "../components/layout/header/hailcx-center-image-header";
import HelpModal from "../components/layout/help-modal";

const CannotProceedPage = () => {
    return (
        <EuiPage paddingSize="none">
            <EuiPageBody>
                <HailCXCenterImageHeader />
                <EuiPageContent style={{ background: '#F5F7FA' }}  borderRadius="none" hasShadow={false} paddingSize="none">
                    <EuiPageContentBody restrictWidth paddingSize="l">
                        <EuiText>
                            <EuiSpacer />
                            <h3 className="eui-textCenter">Apologies, something went wrong. <br/> Please refresh the screen and try again.</h3>
                            <EuiSpacer />
                            <p>Please ensure you have good internet connection to avoid connectivity issues.</p>
                            <p>You may also try re-launching the HAILCX app from the link you would have received in your SMS or email.</p>
                        </EuiText>
                        <EuiSpacer />
                        <EuiText textAlign="center">
                            <HelpModal />
                        </EuiText>
                    </EuiPageContentBody>
                </EuiPageContent>
            </EuiPageBody>
        </EuiPage>
    )
}

export default observer(CannotProceedPage)
